<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import {paginationHelper} from "@/helpers/pagination-helper";
import {jsonUtil} from "@/helpers/json-util";
import Swal from "sweetalert2";
import {errorCatcher} from "@/helpers/error-catcher";
import {swalHelper} from "@/helpers/swal-helper";
import EcatLightboxGallery from "@/components/ecat-lightbox-gallery.vue";
import {productsHelper} from "@/helpers/products-helper";
import ProductDetailsModal from "../../../components/product/product-details-modal.vue";
import CustomCardSubtitle from "@/components/custom-card/custom-card-subtitle.vue";
import ChangeOversizeModal from "../../../components/product/change-oversize-modal.vue";
import {priceHelper} from "@/helpers/price-helper";
import Switches from "vue-switches";
import depotDuplicateProductModal from "../../../components/depot/depot-duplicate-product-modal.vue";
import EcatInput from "@/components/ecat-input.vue";
import CoreProductCard from "@/components/product/core-product-card.vue";
import VirtualList from 'vue-virtual-scroll-list'

/**
 * Admin Products Component
 */
export default {

  components: {
    VirtualList,
    EcatInput,
    ChangeOversizeModal,
    CustomCardSubtitle,
    ProductDetailsModal,
    EcatLightboxGallery,
    Layout,
    Switches,
    PageHeader,
    depotDuplicateProductModal
  },

  data() {
    return {
      loadingPage: true,
      coreProductCard: CoreProductCard,
      loading: false,
      submitted: false,

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100, 500, 1000],
        filter: "",
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        isBusy: false,
        lastSortKey: "-1",
      },

      form: {
        productIds: []
      },

      products: [],

      categories: null,
      taxes: null,

      showModal: false,

      selectedProductImage: {
        visible: false,
        images: []
      },

      activeAccount: null,

      inputPage: '',

      tableColumns: {
        visibleModal: false,

        sku: true,
        image: true,
        warehouseProduct: true,
        name: true,
        categoryName: true,
        ean: true,
        shopProfit: true,
        ecatProfit: true,
        logistics: true,
        profitMargin: true,
        priceLow: true,
        priceHigh: true,
        priceWholesaleHigh: true,
        expect: true,
        blockedOnAllegro: true,
        totalWarehouseStock: true,
        warehouse: true,
        tax: true,
        deliveryTime: true,
        shippingCost: true,
        industry: true
      },

      filtering: {
        showProductsWithEAN: false,
        bestSellingProducts: false,
        showNotAvailableProducts: false,
        sku: "",
        ean: "",
        warehouseProduct: "",
        name: "",
        priceMin: "",
        priceMax: "",
        warehouseId: "",
        categoryId: "",
        industryId: "",
        attributeGroupId: "",
        attribute: ""
      },

      warehouses: null,
      industries: null,
      shippingCosts: null,
      bestSellingProductsIds: [],
      deliveryUnitsMap: null,
      orderByMap: new Map([
        ["sku", "p.sku"],
        ["name", "p.name"],
        ["category", "p.category_id"],
        ["priceHigh", "p.price_high"],
        ["priceLow", "p.price_low"],
        ["priceWholesaleHigh", "p.price_wholesale_high"],
        ["totalWarehouseStock", "p.total_warehouse_stock"],
        ["warehouse", "p.warehouse_id"],
        ["tax", "p.tax_id"],
        ["deliveryTime", "p.delivery_time"],
        ["warehouseProduct", "p.warehouse_product"]
      ])
    };
  },

  watch: {
    "table.currentPage": {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        if (newValue !== oldValue && oldValue !== undefined) {
          if (this.loadingPage) {
            return
          }

          await this.loadProducts();
        }
      },
    },

    "table.perPage": {
      deep: true,
      immediate: true,
      async handler(newValue, oldValue) {
        if (newValue !== oldValue && oldValue !== undefined) {
          if (this.loadingPage) {
            return
          }

          await this.loadProducts();
        }
      },
    }
  },

  async mounted() {
    this.$root.$on("products-change-sortable", async (field) => {
      this.table.sortBy = field
      this.table.sortDesc = !this.table.sortDesc
      await this.loadProducts()
    });

    setTimeout(async () => await this.loadProducts(), 1250)
  },

  methods: {
    async changeOversize() {
      const json = {
        ids: this.form.productIds,
        oversizeType: this.$refs.changeOversizeModal.getOversizeType()
      }

      try {
        await axios.post(`/product/internal/identification/update-oversize/by/product-ids`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        })

        this.form.productIds = []
        Swal.fire("Sukces!", 'Pomyślnie zmieniono status gabarytu dla wybranych produktów', "success").then(() => {
          this.setToFirstPageAndRefresh()
        });
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    openOversizeModal() {
      if (!this.checkSelectedProductsIsNotEmpty()) {
        return
      }

      this.$refs.changeOversizeModal.openModal(() => this.changeOversize())
    },

    async setToFirstPageAndRefresh() {
      this.table.currentPage = 1
      await this.loadProducts()
    },

    async loadShippingCosts() {
      try {
        const productIds = [];
        for (let product of this.products) {
          productIds.push(product.id);
        }

        const json = JSON.stringify({
          "productIds": productIds
        });

        const {data} = await axios.post(`/product/target-shipping-costs`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        if (data && data.data) {
          this.shippingCosts = new Map()

          data.data.forEach(item => {
            const productId = item.productId;
            const carrierData = {
              carrierId: item.carrierId,
              allegroCarrierId: item.allegroCarrierId,
              cost: item.cost,
              name: item.name
            };

            if (this.shippingCosts.has(productId)) {
              this.shippingCosts.get(productId).push(carrierData);
            } else {
              this.shippingCosts.set(productId, [carrierData]);
            }
          });
        }
      } catch (error) {
        console.log(error)
      }
    },

    getShippingCost(productId) {
      if (!this.shippingCosts || this.shippingCosts.size === 0) {
        return null;
      }

      return this.shippingCosts.get(productId) || null;
    },

    async loadWarehouses() {
      try {
        if (this.products.length === 0) {
          return;
        }

        const ids = [];
        for (let product of this.products) {
          ids.push(product.warehouseId);
        }

        const json = JSON.stringify({
          "ids": ids
        });

        const result = await axios.post(`/warehouse/fetch`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.warehouses = result.data
        await this.loadIndustries()
      } catch (error) {
        console.log(error)
      }
    },

    selectOrUnSelectProduct(product) {
      if (this.form.productIds.includes(product.id)) {
        this.form.productIds = this.form.productIds.filter(element => element !== product.id)
      } else {
        this.form.productIds.push(product.id)
      }
    },

    async loadIndustries() {
      try {
        if (this.warehouses.length === 0) {
          return;
        }

        const ids = [];
        for (let warehouse of this.warehouses) {
          ids.push(warehouse.industryId);
        }

        const json = JSON.stringify({
          "ids": ids
        });

        const result = await axios.post(`/industry/fetch`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.industries = new Map(result.data.map((obj) => [obj.id, obj]));
      } catch (error) {
        console.log(error)
      }
    },

    getWarehouse(id) {
      if (!this.warehouses) {
        return null
      }

      for (const warehouse of this.warehouses) {
        if (warehouse.id === id) {
          return warehouse
        }
      }

      return {}
    },

    getIndustry(id) { // TODO: make helper
      if (!this.industries) {
        return null
      }

      const industry = this.industries.get(id)
      return industry ? industry : null;
    },

    changeFilterShowProductsWithEan() {
      this.filtering.showProductsWithEAN = !this.filtering.showProductsWithEAN
      this.setToFirstPageAndRefresh()
    },

    changeBestSellingProducts() {
      this.filtering.bestSellingProducts = !this.filtering.bestSellingProducts;
      this.setToFirstPageAndRefresh()
    },

    clearFilters() {
      this.filtering.showProductsWithEAN = false
      this.filtering.bestSellingProducts = false
      this.filtering.showNotAvailableProducts = false
      this.filtering.sku = ""
      this.filtering.ean = ""
      this.filtering.warehouseProduct = ""
      this.filtering.name = ""
      this.filtering.priceMin = ""
      this.filtering.priceMax = ""
      this.filtering.warehouseId = ""
      this.filtering.categoryId = ""
      this.filtering.industryId = ""
      this.filtering.attributeGroupId = ""
      this.setToFirstPageAndRefresh()
    },

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('menuitems.store.list.products'),
          active: true
        }
      ]
    },

    getFields() {
      const fields = []
      fields.push({key: 'element', label: ""})

      if (this.tableColumns.sku) {
        fields.push({key: 'sku', sortable: true, label: "SKU"})
      }

      if (this.tableColumns.image) {
        fields.push({key: "image", label: this.$t('table.image')})
      }

      if (this.tableColumns.warehouseProduct) {
        fields.push({key: "warehouseProduct", sortable: true, label: 'Index Hurtowni'})
      }

      if (this.tableColumns.name) {
        fields.push({key: "name", label: this.$t('message.name')})
      }

      if (this.tableColumns.categoryName) {
        fields.push({key: "category", sortable: true, label: this.$t('table.category')})
      }

      if (this.tableColumns.ean) {
        fields.push({key: "ean", sortable: true, label: 'EAN'})
      }

      if (this.tableColumns.profitMargin) {
        fields.push({
          key: "warehouseProfitMargin",
          sortable: true,
          label: this.$t('table.margin'),
          formatter: productsHelper.profitMarginFormatter
        })
      }

      if (this.tableColumns.shopProfit) {
        fields.push({
          key: "shopProfit",
          label: 'Zysk sklepu',
          formatter: (value, key, item) => productsHelper.profitFormatter(value, key, item, this.getTaxRate(item.taxId))
        })
      }

      if (this.tableColumns.ecatProfit) {
        fields.push({
          key: "ecatProfit",
          label: 'Zysk ECAT eCommerce (Full Dropshipping)',
          formatter: (value, key, item) => productsHelper.ecatProfitFormatter(value, key, item, this.getTaxRate(item.taxId))
        })
      }

      if (this.tableColumns.logistics) {
        fields.push({
          key: "logistics",
          label: 'Koszty logistyki i księgowości',
          formatter: (value, key, item) => productsHelper.logisticsFormatter(value, key, item, this.getTaxRate(item.taxId))
        })
      }

      if (this.tableColumns.priceHigh) {
        fields.push({
          key: "priceHigh",
          sortable: true,
          label: this.$t('table.price-high'),
          formatter: productsHelper.priceHighFormatter
        })
      }

      if (this.tableColumns.priceLow) {
        fields.push({
          key: "priceLow",
          sortable: true,
          label: "Cena sklepu netto",
          formatter: (value, key, item) => productsHelper.priceLowFormatter(value, key, item, this.getTaxRate(item.taxId))
        })
      }

      if (this.tableColumns.priceWholesaleHigh) {
        fields.push({
          key: "priceWholesaleHigh",
          sortable: true,
          label: this.$t('table.price-wholesale-high'),
          formatter: value => priceHelper.format(value, this.$store.getters["market/currentCurrency"])
        })
      }

      if (this.tableColumns.expect) {
        fields.push({key: "expect", sortable: true, label: this.$t('products.table.expect')})
      }

      if (this.tableColumns.blockedOnAllegro) {
        fields.push({key: "blockedOnAllegro", sortable: true, label: "Czy zablokowana wysyłka na Allegro"})
      }

      if (this.tableColumns.totalWarehouseStock) {
        fields.push({key: "totalWarehouseStock", sortable: true, label: this.$t('table.total-warehouse-stock')})
      }

      if (this.tableColumns.warehouse) {
        fields.push({key: "warehouse", sortable: true, label: this.$t('products.table.warehouse')})
      }

      if (this.tableColumns.tax) {
        fields.push({key: "tax", sortable: true, label: this.$t('products.table.tax-rate')})
      }

      if (this.tableColumns.deliveryTime) {
        fields.push({key: "deliveryTime", sortable: true, label: this.$t('products.table.delivery-time')})
      }

      if (this.tableColumns.industry) {
        fields.push({key: "industry", sortable: true, label: 'Branża'})
      }

      if (this.tableColumns.shippingCost) {
        fields.push({key: "shippingCost", sortable: true, label: 'Koszt dostawy'})
      }

      fields.push({key: "action", label: this.$t('table.actions')})
      return fields
    },

    async loadProducts() {
      this.loading = true;
      if (this.filtering.bestSellingProducts) {
        await this.loadBestSellingProducts()
      }

      const selectedMarket = this.$store.getters["market/selectedMarket"];
      if (!selectedMarket) {
        return
      }

      try {
        if (this.table.currentPage < 10) {
          this.table.lastSortKey = "-1"
        }

        let page = this.table.currentPage - 1;
        if (page > 0) {
          page = this.table.currentPage * this.table.perPage - this.table.perPage;
        }

        let orderBy = {}
        if (this.table.sortBy) {
          const orderByElement = this.orderByMap.get(this.table.sortBy)
          if (orderByElement) {
            orderBy = JSON.stringify({
              [orderByElement]: this.table.sortDesc ? "DESC" : "ASC"
            })
          }
        }

        const {data} = await axios.get(`/product`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            page: this.table.lastSortKey === "-1" ? page : null,
            searchAfter: this.table.lastSortKey !== "-1" ? this.table.lastSortKey : null,
            size: this.table.perPage,
            showProductsWithEan: this.filtering.showProductsWithEAN,
            showNotAvailableProducts: this.filtering.showNotAvailableProducts,
            bestSellingProducts: this.filtering.bestSellingProducts,
            filterByProductIds: this.bestSellingProductsIds.join(","),
            warehouseProduct: this.filtering.warehouseProduct,
            sku: this.filtering.sku,
            ean: this.filtering.ean,
            name: this.filtering.name,
            priceMin: this.filtering.priceMin ? this.filtering.priceMin : null,
            priceMax: this.filtering.priceMax ? this.filtering.priceMax : null,
            warehouseId: this.filtering.warehouseId,
            categoryId: this.filtering.categoryId,
            industryId: this.filtering.industryId,
            filter: this.table.filter,
            attributeGroupId: this.filtering.attributeGroupId,
            attributeValue: this.filtering.attribute,
            marketId: selectedMarket.id,
            orderBy: orderBy
          }
        });

        const { products, count, lastSortKey } = data
        this.products = products
        this.table.totalRows = count
        this.table.rows = count

        if (this.table.currentPage >= 10) {
          this.table.lastSortKey = lastSortKey
        }

        await Promise.all([
          this.loadCategories(),
          this.loadTaxes(),
          this.loadWarehouses(),
          this.loadShippingCosts()
        ]);

        this.loading = false;
      } catch (error) {
        console.log(error)
        this.products = []
        this.table.totalRows = 0
        this.table.rows = 0
        this.loading = false;
      }
    },

    async loadBestSellingProducts() {
      try {
        const result = await axios.get(`/product/best-selling`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
        });

        await this.loadProductVariationsByIds(result.data)
      } catch (error) {
        console.log(error)
      }
    },

    async loadProductVariationsByIds(bestSellingProducts) {
      try {
        const ids = [];
        for (const bestSellingProduct of bestSellingProducts) {
          ids.push(bestSellingProduct.productVariationId);
        }

        const json = JSON.stringify({
          "ids": ids
        });

        const result = await axios.post(`/product/variation/list-by-ids`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
        });

        const bestSellingProductsIds = [];
        for (let productVariation of result.data) {
          bestSellingProductsIds.push(productVariation.productId);
        }

        this.bestSellingProductsIds = bestSellingProductsIds
      } catch (error) {
        console.log(error)
      }
    },

    async loadCategories() {
      if (this.products.length === 0) {
        return;
      }

      const ids = [];
      for (let product of this.products) {
        ids.push(product.categoryId);
      }

      const json = JSON.stringify({
        "ids": ids
      });

      const result = await axios.post(`/category`, json, {
        data: {},
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      });

      this.categories = new Map(result.data.map((obj) => [obj.id, {"id:": obj.id, "id": obj.id, "name": obj.name}]));
    },

    async loadTaxes() {
      if (this.products.length === 0) {
        return;
      }

      const ids = [];
      for (let product of this.products) {
        ids.push(product.taxId);
      }

      const json = JSON.stringify({
        "ids": ids
      });

      const result = await axios.post(`/tax/list-by-ids`, json, {
        data: {},
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      });

      this.taxes = new Map(result.data.map((obj) => [obj.id, obj]));
    },

    getShopName(id) {
      for (const shop of this.shops) {
        if (shop.id === id) {
          return shop.name;
        }
      }

      return "";
    },

    getCategory(categoryId) {
      if (this.categories === null) {
        return {}
      }

      const category = this.categories.get(categoryId);
      if (!category) {
        return {};
      }

      return category;
    },

    getTaxRate(taxId) {
      const tax = this.taxes.get(taxId);
      if (!tax) {
        return "";
      }

      return tax.rate;
    },

    getTime(value) {
      const humanizeDuration = require("humanize-duration");
      return humanizeDuration(value, {language: this.$store.getters["translation/getCurrentLocaleOrFallback"]});
    },

    refreshSlidebar() {
      this.$refs.slidebar.refresh()
    },

    selectProductImages(images) {
      this.selectedProductImage.images = images
      this.selectedProductImage.visible = true
    },

    hideSelectedProductImage() {
      this.selectedProductImage.images = []
      this.selectedProductImage.visible = false
    },

    selectAllProducts() {
      const ids = this.products.map(product => product.id);
      const newProductIds = ids.filter(id => !this.form.productIds.includes(id));

      if (newProductIds.length > 0) {
        this.form.productIds.push(...newProductIds);
      } else {
        this.form.productIds = this.form.productIds.filter(id => !ids.includes(id))
      }
    },

    isSameProductIds() {
      if (this.products.length === 0) {
        return false
      }

      const ids = this.products.map(product => product.id);
      return ids.every(id => this.form.productIds.includes(id))
    },

    highlightRow(item) {
      if (!item) {
        return ''
      }

      return this.form.productIds.includes(item.id) ? 'highlighted-product' : ''
    },

    async loadDeliveryUnits() {
      try {
        const {data} = await axios.get(`/delivery-unit`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
        });

        this.deliveryUnitsMap = new Map(data.deliveryUnits.map((obj) => [obj.deliveryUnit, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    getDeliveryUnit(unit) {
      if (!this.deliveryUnitsMap) {
        return null
      }

      return this.deliveryUnitsMap.get(unit) || null
    },

    getProductDeliveryTime(product) {
      if (product.deliveryTime && product.deliveryUnit) {
        const deliveryUnit = this.getDeliveryUnit(product.deliveryUnit)
        if (deliveryUnit) {
          return product.deliveryTime + " " + deliveryUnit.name
        }
      }

      const warehouse = this.getWarehouse(product.warehouseId)
      if (warehouse) {
        const deliveryUnit = this.getDeliveryUnit(warehouse.deliveryUnit)
        if (deliveryUnit) {
          return warehouse.deliveryTime + " " + deliveryUnit.name
        }
      }

      return this.$t('products.no-delivery-time')
    },

    checkSelectedProductsIsNotEmpty() {
      if (this.form.productIds.length === 0) {
        Swal.fire(this.$t('message.error'), "Najpierw zaznacz przynajmniej jeden produkt!", "error");
        return false
      }

      return true
    },

    endAuctions() {
      if (!this.checkSelectedProductsIsNotEmpty()) {
        return
      }

      axios.get(`/allegro/auction/fetch/auction-ids`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          "productIds": this.form.productIds.join(",")
        }
      }).then(({data}) => {
        this.form.productIds = []
        const ids = data.ids

        swalHelper.yesOrNoCustomizable(() => {
          this.endAuctionsWithIds(ids)
        }, "warning", "Czy na pewno?", `${ids.length} aukcji zostanie zakończonych`, "Zakończ", "Anuluj", "btn btn-danger", "btn btn-secondary")
      }).catch((error) => {
        errorCatcher.catchErrors(error)
      });
    },

    blockOnAllegro() {
      if (!this.checkSelectedProductsIsNotEmpty()) {
        return
      }

      swalHelper.yesOrNoCustomizable(() => {
        this.sendBlockRequest(true, "produktów zostało zablokowanych na Allegro")
      }, "warning", "Czy na pewno?", `${this.form.productIds.length} produktów zostanie zablokowanych na Allegro`, "Zablokuj", "Anuluj", "btn btn-danger", "btn btn-secondary")
    },

    unBlockOnAllegro() {
      if (!this.checkSelectedProductsIsNotEmpty()) {
        return
      }

      swalHelper.yesOrNoCustomizable(() => {
        this.sendBlockRequest(false, "produktów zostało odblokowanych na Allegro")
      }, "warning", "Czy na pewno?", `${this.form.productIds.length} produktów zostanie odblokowanych na Allegro`, "Odblokuj", "Anuluj", "btn btn-success", "btn btn-secondary")
    },

    sendBlockRequest(blocked, message) {
      const json = JSON.stringify({
        productIds: this.form.productIds,
        blocked: blocked
      });

      axios.post(`/product/change-allegro-blocked-status`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then(() => {
        Swal.fire("Sukces!", `${this.form.productIds.length} ${message}`, "success");
        this.form.productIds = []
        this.setToFirstPageAndRefresh()
      }).catch((error) => {
        errorCatcher.catchErrors(error)
      })
    },

    endAuctionsWithIds(ids) {
      const json = JSON.stringify({
        "auctionIds": ids
      });

      axios.post(`/allegro/auction/end-auctions`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then(({data}) => {
        Swal.fire("Sukces!", `${data.countEnded} aukcji zostało zakończonych z ${ids.length} możliwych`, "success");
      }).catch((error) => {
        errorCatcher.catchErrors(error)
      })
    }

  },

  computed: {
    Swal() {
      return Swal
    },

    jsonUtil() {
      return jsonUtil
    },

    paginationHelper() {
      return paginationHelper
    }

  },

  async created() {
    try {
      await this.loadDeliveryUnits()
    } catch (error) {
      console.log(error)
    }

    this.loadingPage = false
  },

  beforeDestroy() {
    this.$root.$off("products-change-sortable");
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('menuitems.store.list.products')" :items="getItems()"/>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="button-items mb-2" v-if="$store.getters['role/hasPermission']('TECHNICAL_DEPARTMENT')">
              <router-link to="/dashboard/core/add-product" tag="a" class="btn btn-success">
                Dodaj produkt
              </router-link>
            </div>

            <p>Akcje masowe (wymagają zaznaczenia wierszy tabeli)</p>
            <div class="button-items mb-2">
              <b-button v-if="$store.getters['role/hasPermission']('TECHNICAL_DEPARTMENT')" variant="danger" @click="endAuctions">Zakończ aukcję z wybranymi produktami</b-button>
              <b-button variant="danger" @click="blockOnAllegro">Zablokuj wysyłkę na Allegro</b-button>
              <b-button variant="success" @click="unBlockOnAllegro">Odblokuj wysyłkę na Allegro</b-button>
              <b-button variant="danger" @click="openOversizeModal">Zmień status gabarytu dla wybranych produktów
              </b-button>
            </div>

            <custom-card-subtitle title="Filtrowanie"/>

            <div class="button-items mb-2">
<!--              <a @click="tableColumns.visibleModal = true" class="btn btn-primary">-->
<!--                Dostosuj kolumny-->
<!--              </a>-->

              <a @click="changeFilterShowProductsWithEan" class="btn btn-primary">
                {{ filtering.showProductsWithEAN ? 'Pokaż wszystkie produkty' : 'Pokaż produkty posiadające EAN' }}
              </a>
            </div>

            <div>
              <a @click="changeBestSellingProducts" class="btn btn-primary mb-2">
                Pokaż produkty najlepiej sprzedające się w ciągu 30 dni:
                {{ filtering.bestSellingProducts ? 'Tak' : 'Nie' }}
              </a>
            </div>

            <div class="col-lg-4">
              <div class="d-flex flex-sm-column">
                <label class="font-size-13">
                  Pokaż wszystkie produkty (nawet historyczne):
                  {{ filtering.showNotAvailableProducts ? "Tak" : "Nie" }}
                </label>

                <switches :emit-on-mount="false" @input="setToFirstPageAndRefresh"
                          v-model="filtering.showNotAvailableProducts"
                          class="my-0" color="primary"/>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-2 col-12">
                <ecat-input
                    id="product-name"
                    label="Nazwa produktu"
                    v-model="filtering.name"
                    type="text"
                    @should-reload="setToFirstPageAndRefresh"
                />
              </div>

              <div class="col-lg-2 col-12">
                <ecat-input
                    id="sku"
                    label="SKU"
                    v-model="filtering.sku"
                    type="text"
                    @should-reload="setToFirstPageAndRefresh"
                />
              </div>

              <div class="col-lg-2 col-12">
                <ecat-input
                    id="warehouseProduct"
                    label="Index Hurtowni"
                    v-model="filtering.warehouseProduct"
                    type="text"
                    @should-reload="setToFirstPageAndRefresh"
                />
              </div>

              <div class="col-lg-2 col-12">
                <ecat-input
                    id="ean"
                    label="EAN"
                    v-model="filtering.ean"
                    type="number"
                    @should-reload="setToFirstPageAndRefresh"
                />
              </div>

              <div class="col-12">
              </div>

              <div class="col-lg-2 col-12">
                <ecat-input
                    id="price-min"
                    label="Cena minimalna"
                    v-model="filtering.priceMin"
                    type="number"
                    @should-reload="setToFirstPageAndRefresh"
                />
              </div>

              <div class="col-lg-2 col-12">
                <ecat-input
                    id="price-max"
                    label="Cena maksymalna"
                    v-model="filtering.priceMax"
                    type="number"
                    @should-reload="setToFirstPageAndRefresh"
                />
              </div>

              <div class="col-lg-2 col-12">
                <ecat-multiselect
                    multi-select-id="1"
                    :set-value="filtering.warehouseId"
                    @change="value => filtering.warehouseId = value"
                    @select="setToFirstPageAndRefresh()"
                    save-id="id"
                    view-id="name"
                    label="Hurtownia"
                    reset-option-name="Wszystkie hurtownie"
                    placeholder="Wszystkie hurtownie"
                    fetch-one-url="/warehouse"
                    load-url="/warehouse/list/pagination"
                    query-url="/warehouse/by-name"
                    param="name"
                    :can-unselect="true"
                    :custom-params="{withProducts: true, industryId: filtering.industryId, marketId: $store.getters['market/getMarketId']}"
                />
              </div>

              <div class="col-lg-2 col-12">
                <ecat-multiselect
                    multi-select-id="2"
                    label="Kategoria"
                    :set-value="filtering.categoryId"
                    @change="value => filtering.categoryId = value"
                    @select="setToFirstPageAndRefresh()"
                    view-id="name"
                    save-id="id"
                    :can-unselect="true"
                    reset-option-name="Wszystkie kategorie"
                    placeholder="Wszystkie kategorie"
                    fetch-one-url="/category"
                    load-url="/category/list/pagination"
                    query-url="/category/by-name"
                    param="name"
                    :custom-params="{'withProducts': true}"
                />
              </div>

              <div class="col-lg-2 col-12">
                <ecat-multiselect
                    multi-select-id="3"
                    :set-value="filtering.industryId"
                    @change="value => filtering.industryId = value"
                    @select="setToFirstPageAndRefresh()"
                    :can-unselect="true"
                    save-id="id"
                    view-id="name"
                    :label="$t('message.industry')"
                    reset-option-name="Wszystkie branże"
                    placeholder="Wszystkie branże"
                    fetch-one-url="/industry"
                    load-url="/industry/list/pagination"
                    query-url="/industry/by-name"
                    list-name="industries"
                    param="name"
                    :custom-params="{withProducts: true, marketId: $store.getters['market/getMarketId'] }"
                />
              </div>

              <div class="col-lg-2 col-12">
              </div>

              <div class="col-lg-2 col-12">
                <ecat-multiselect
                    multi-select-id="4"
                    :set-value="filtering.attributeGroupId"
                    @change="value => filtering.attributeGroupId = value"
                    @select="setToFirstPageAndRefresh()"
                    :can-unselect="true"
                    save-id="id"
                    view-id="name"
                    label="Atrybut"
                    placeholder="Wszystkie atrybuty"
                    fetch-one-url="/attribute/group"
                    load-url="/attribute/group/list/pagination"
                    query-url="/attribute/group/by-name"
                    param="name"
                />
              </div>

              <div class="col-lg-2 col-12">
                <ecat-input
                    label="Wartość atrybutu"
                    v-model="filtering.attribute"
                    type="text"
                    @should-reload="setToFirstPageAndRefresh"
                />
              </div>

              <div class="col-12">
                <div class="float-right">
                  <a @click="clearFilters" class="btn btn-outline-warning w-sm mb-2 mx-2">Wyczyść filtry</a>
                  <a @click="loadProducts" class="btn btn-primary w-sm mb-2">Szukaj</a>
                </div>
              </div>
            </div>

            <ecat-lightbox-gallery :visible="selectedProductImage.visible" :images="selectedProductImage.images"
                                   @hide="hideSelectedProductImage"/>

            <div class="py-2">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      {{ $t('table.show') }}&nbsp;
                      <ecat-form-select id="1" v-model="table.perPage" size="sm"
                                        :options="table.pageOptions"
                                        @loaded="setToFirstPageAndRefresh"></ecat-form-select>&nbsp;{{
                        $t('table.entries')
                      }}
                    </label>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                    <label class="d-inline-flex align-items-center">
                      {{ $t('table.search') }}
                      <b-form-input v-model="table.filter" class="form-control form-control-sm ml-2" type="search"
                                    @keyup.enter="setToFirstPageAndRefresh"
                                    @input="setToFirstPageAndRefresh"></b-form-input>
                    </label>
                  </div>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-sm-12 col-md-6">
                  <b-button
                      class="btn-select-all mr-2 d-inline-flex align-items-center justify-content-center py-2 px-3"
                      @click="selectAllProducts">

                    <i class="font-size-20 mr-2" :class="isSameProductIds() ? 'far fa-check-square' : 'far fa-square'"/>
                    <div class="text font-weight-bold">
                      Zaznacz wszystkie
                    </div>
                  </b-button>
                  <b-button variant="primary" class="w-xxl" @click="Swal.fire('Dostępne wkrótce')">
                    Dostosuj dane
                  </b-button>
                </div>

                <div class="col-sm-12 col-md-6 d-inline-flex justify-content-end">

                  <div class="dataTables_paginate paging_simple_numbers">
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination v-model="table.currentPage" :total-rows="table.rows" :per-page="table.perPage" :hide-goto-end-buttons="true"/>
                    </ul>
                  </div>
<!--                  <div class="align-items-center">-->
<!--                    <label class="d-inline-flex align-items-center mx-2 mb-0">-->
<!--                      <b-form-input v-model="table.inputPage" class="form-control form-control-sm ml-2 form-xs"/>-->
<!--                    </label>-->
<!--                    <b-button variant="primary" @click="paginationHelper.setCurrentPage(table, table.inputPage)">Idź do-->
<!--                      strony-->
<!--                    </b-button>-->
<!--                  </div>-->
                </div>
              </div>

              <template v-if="loading">
                <div class="text-center">
                  <h5>{{ $t('message.loading') }}</h5>
                  <span aria-hidden="true" class="m-2 spinner-border text-primary"/>
                </div>
              </template>
              <template v-else>
                <template v-if="!products || products.length === 0">
                  <div class="col-lg-12 text-center product-body py-3 mb-2">
                    Brak danych
                  </div>
                </template>
                <template v-else>
                  <div class="row my-3">
                    <virtual-list
                        :keeps="10"
                        :estimate-size="15"
                        :page-mode="true"
                        style="width: 100%"
                        data-key="id"
                        :data-sources="products"
                        :data-component="coreProductCard"
                        :extra-props="{
                          'productIds': form.productIds,
                          'categories': categories,
                          'warehouses': warehouses,
                          'industries': industries,
                          'taxes': taxes,
                          'delivery-units': deliveryUnitsMap,
                          'shipping-costs': shippingCosts,
                          'sortable-by': table.sortDesc,
                          'sortable-by-field': table.sortBy
                        }"
                        @open-details-modal="$refs.productDetailsModal.openModal"
                        @open-duplicate-product-modal="$refs.depotDuplicateProductModal.openModal"
                        @select-or-unselect-product="selectOrUnSelectProduct"
                        @open-images-modal="selectProductImages"
                    />
                  </div>
                </template>
              </template>

              <div class="row">
                <div class="col">
                  <div class="float-left">
                    <p>{{
                        $t('table.entries-footer', {
                          'amount': paginationHelper.getElementsAmount(table),
                          'elements': paginationHelper.getElementsCount(table),
                          'all': table.totalRows
                        })
                      }}</p>
                  </div>
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination v-model="table.currentPage" :total-rows="table.rows" :per-page="table.perPage" :hide-goto-end-buttons="true" />
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <product-details-modal ref="productDetailsModal"/>
    <depot-duplicate-product-modal ref="depotDuplicateProductModal"/>

    <b-modal
        id="modal-3"
        v-model="tableColumns.visibleModal"
        title="Wybierz kolumny do pokazania/ukrycia"
        title-class="font-18"
        hide-footer
        @hide="tableColumns.visibleModal = false"
        @esc="tableColumns.visibleModal = false">
      <form>
        <div class="form-group">
          <label class="mx-2"><input v-model="tableColumns.sku" type="checkbox"/> SKU</label>
          <label class="mx-2"><input v-model="tableColumns.image" type="checkbox"/> Zdjęcie</label>
          <label class="mx-2"><input v-model="tableColumns.warehouseProduct" type="checkbox"/> Index hurtowni</label>
          <label class="mx-2"><input v-model="tableColumns.name" type="checkbox"/> Nazwa produktu</label>
          <label class="mx-2"><input v-model="tableColumns.categoryName" type="checkbox"/> Nazwa kategorii</label>
          <label class="mx-2"><input v-model="tableColumns.ean" type="checkbox"/> EAN</label>
          <label class="mx-2"><input v-model="tableColumns.profitMargin" type="checkbox"/> Narzut</label>
          <label class="mx-2"><input v-model="tableColumns.shopProfit" type="checkbox"/> Zysk sklep</label>
          <label class="mx-2"><input v-model="tableColumns.logistics" type="checkbox"/> Koszty logistyki i
            księgowości</label>
          <label class="mx-2"><input v-model="tableColumns.ecatProfit" type="checkbox"/> Zysk ECAT eCommerce (Full
            Dropshipping)</label>
          <label class="mx-2"><input v-model="tableColumns.priceLow" type="checkbox"/> Cena sklepu netto</label>
          <label class="mx-2"><input v-model="tableColumns.priceHigh" type="checkbox"/> Cena sklepu brutto</label>
          <label class="mx-2"><input v-model="tableColumns.priceWholesaleHigh" type="checkbox"/> Cena hurtowni
            netto</label>
          <label class="mx-2"><input v-model="tableColumns.expect" type="checkbox"/> Dozwolony do sprzedaży na
            Allegro</label>
          <label class="mx-2"><input v-model="tableColumns.blockedOnAllegro" type="checkbox"/> Czy zablokowana wysyłka
            na Allegro</label>
          <label class="mx-2"><input v-model="tableColumns.totalWarehouseStock" type="checkbox"/> Ilość sztuk</label>
          <label class="mx-2"><input v-model="tableColumns.warehouse" type="checkbox"/> Hurtownia</label>
          <label class="mx-2"><input v-model="tableColumns.tax" type="checkbox"/> Stawka podatku</label>
          <label class="mx-2"><input v-model="tableColumns.deliveryTime" type="checkbox"/> Czas dostawy</label>
          <label class="mx-2"><input v-model="tableColumns.industry" type="checkbox"/> Branża</label>
          <label class="mx-2"><input v-model="tableColumns.shippingCost" type="checkbox"/> Koszt dostawy</label>
        </div>
      </form>
    </b-modal>

    <change-oversize-modal ref="changeOversizeModal"/>

  </Layout>
</template>