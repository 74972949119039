<template>
  <div class="product-card mb-2">
    <div class="product-header row nopadding">
      <div class="col-xl-12 product-header py-3 d-flex flex-row align-items-center flex-wrap justify-content-between">
        <div class="d-inline-flex wrap-text">
          <div class="product-header-select d-flex flex-row align-items-center justify-content-center">
            <div class="custom-control custom-checkbox">
              <input
                  :value="product().id"
                  @change="$parent.$parent.$emit('select-or-unselect-product', product())"
                  :checked="productIds.includes(product().id)"
                  type="checkbox"
                  class="custom-control-input"
                  :id="`check-${product().id}`"
                  :class="productIds.includes(product().id) ? 'custom-control-label-selected' : ''"
              />
              <label class="custom-control-label" :for="`check-${product().id}`">&nbsp;</label>
            </div>
          </div>
          <div class="product-header-container d-inline-flex">
            <div class="product-header-image mx-3">
              <template v-if="product().images.length > 0">
                <a class="custom-image" @click="$parent.$parent.$emit('open-images-modal', product().images)">
                  <v-lazy-image
                      :src="product().images[0]"
                      src-placeholder="https://i.imgur.com/OI4ACNk.gif"
                      alt="Product Image"
                      class="custom-image"
                  />
                </a>

              </template>
              <template v-else>
                <span>-</span>
              </template>
            </div>

            <div class="product-header-box d-flex align-items-center">
              <div class="product-header-title font-weight-bold">
                {{ product().name }}
              </div>
            </div>
          </div>
        </div>
        <div class="product-header-details">
          <button class="btn-lg btn-product-details px-4" @click="$parent.$parent.$emit('open-details-modal', product())">
            Szczegóły
          </button>
        </div>
      </div>
    </div>

    <div class="product-body">
      <div class="col-xl-12 row py-2">
        <div class="col-sm-4">
          <product-detail-card title="SKU:" :description="product().sku.toString()" sort-by-field="sku"
                               :sortable-by="sortableBy" :sortable-by-field="sortableByField"/>

          <product-detail-card title="Index Hurtowni:" :description="product().warehouseProduct"
                               sort-by-field="warehouseProduct" :sortable-by="sortableBy"
                               :sortable-by-field="sortableByField"/>

          <product-detail-card title="Nazwa kategorii:" :description="category() ? category().name : '-'"
                               sort-by-field="categoryId" :sortable-by="sortableBy"
                               :sortable-by-field="sortableByField"/>

          <product-detail-card title="EAN:">
            <render-product-eans :product="product()" />
          </product-detail-card>

          <product-detail-card title="Narzut:"
                               :description="productsHelper.profitMarginFormatter(null, null, product())"
                               sort-by-field="warehouseProfitMargin" :sortable-by="sortableBy"
                               :sortable-by-field="sortableByField"/>

          <product-detail-card title="Zysk sklepu:"
                               :description="productsHelper.profitFormatter(null, null, product(), taxRate())"/>

          <product-detail-card title="Zysk ECAT eCommerce (Full Dropshipping):"
                               :description="productsHelper.ecatProfitFormatter(null, null, product(), taxRate())"/>
        </div>
        <div class="col-sm-4">
          <product-detail-card title="Koszty logistyki i księgowości:"
                               :description="productsHelper.logisticsFormatter(null, null, product(), taxRate())"/>

          <product-detail-card title="Cena sklepu brutto:"
                               :description="productsHelper.priceHighFormatter(null, null, product())"
                               sort-by-field="priceHigh" :sortable-by="sortableBy"
                               :sortable-by-field="sortableByField"/>

          <product-detail-card title="Cena sklepu netto:"
                               :description="productsHelper.priceLowFormatter(null, null, product(), taxRate())"
                               sort-by-field="priceLow" :sortable-by="sortableBy" :sortable-by-field="sortableByField"/>

          <product-detail-card title="Cena hurtowni netto:"
                               :description="productsHelper.parsePrice(product().priceWholesaleHigh)"
                               sort-by-field="priceWholesaleHigh" :sortable-by="sortableBy"
                               :sortable-by-field="sortableByField"/>

          <product-detail-card
              title="Dozwolony do sprzedaży na Allegro:"
              :badge="true"
              :badge-class="productHelper.getAllegroSendStatus(product()) ? 'badge-soft-success' : 'badge-soft-danger'"
              :badge-text="$t(productHelper.getAllegroSendStatus(product()) ? 'message.yes' : 'message.no')"
          />

          <product-detail-card
              title="Zablokowana wysyłka na Allegro:"
              :badge="true"
              :badge-class="product().allegroBlocked ? 'badge-soft-success' : 'badge-soft-danger'"
              :badge-text="$t(product().allegroBlocked ? 'message.yes' : 'message.no')"
              sort-by-field="allegroBlocked" :sortable-by="sortableBy"
              :sortable-by-field="sortableByField"
          />

          <product-detail-card
              title="Ilość sztuk:"
              :description="Number(product().totalWarehouseStock + product().totalDepotStock - product().amountReserved).toString()"
              sort-by-field="totalWarehouseStock" :sortable-by="sortableBy"
              :sortable-by-field="sortableByField"
          />
        </div>

        <div class="col-sm-4">
          <product-detail-card
              title="Hurtownia:"
              :description="warehouse() ? warehouse().name : ''"
          />

          <product-detail-card
              title="Stawka podatku:"
              :description="`${taxRate().toString()}%`"
          />

          <product-detail-card
              title="Czas wysyłki:"
              :description="deliveryTime()"
          />

          <product-detail-card
              title="Branża:"
              :description="industry() ? industry().name : '-'"
          />

          <product-detail-card title="Koszt dostawy:" :description="shippingCostsText()" />

          <template v-if="$store.getters['role/hasPermission']('TECHNICAL_DEPARTMENT')">
            <span class="font-weight-bold product-title-color font-size-14 mr-1">
              Akcje:
            </span>

            <a :href="`/dashboard/admin/product/show/${product().id}`" class="clickable-element mr-3 text-primary"><i
                class="mdi mdi-information font-size-18"></i></a>

            <a @click="$parent.$parent.$emit('open-duplicate-product-modal', product())" id="duplicate"
               class="clickable-element mr-3 text-primary"><i class="mdi mdi-content-copy font-size-18"></i></a>
            <b-tooltip target="duplicate" placement="left">Duplikuj</b-tooltip>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ProductDetailCard from "@/components/product/product-detail-card.vue";
import VLazyImage from "v-lazy-image/v2";
import {dateUtil} from "@/helpers/date-util";
import {productHelper} from "@/helpers/product-helper";
import {productsHelper} from "@/helpers/products-helper";
import {priceHelper} from "@/helpers/price-helper";
import RenderProductEans from "@/components/render-product-eans.vue";

export default {
  components: {RenderProductEans, VLazyImage, ProductDetailCard},

  props: {
    source: {
      type: Object,
      default() {
        return {}
      }
    },

    sortableBy: {
      type: Boolean,
      default: false
    },

    sortableByField: {
      type: String,
      default: ""
    },

    productIds: {
      type: Array,
    },

    categories: {
      type: Map,
      required: true
    },

    warehouses: {
      type: Array,
      required: true
    },

    industries: {
      type: Map,
      required: true
    },

    taxes: {
      type: Map,
      required: true
    },

    deliveryUnits: {
      type: Map,
      required: true
    },

    shippingCosts: {
      type: Map,
      required: true
    }
  },

  computed: {
    productsHelper() {
      return productsHelper
    },

    productHelper() {
      return productHelper
    },

    dateUtil() {
      return dateUtil
    }
  },

  methods: {
    product() {
      return this.source
    },
    
    category() {
      if (!this.categories || !this.product().categoryId) {
        return null
      }

      return this.categories.get(this.product().categoryId) || null;
    },

    warehouse() {
      if (!this.warehouses || !this.product().warehouseId) {
        return null
      }

      for (const warehouse of this.warehouses) {
        if (warehouse.id === this.product().warehouseId) {
          return warehouse
        }
      }

      return null
    },

    industry() {
      const warehouse = this.warehouse()
      if (!this.industries || !warehouse) {
        return null
      }

      return this.industries.get(warehouse.industryId) || null
    },

    taxRate() {
      if (!this.taxes || this.taxes.size === 0 || !this.product().taxId) {
        return 0
      }

      const tax = this.taxes.get(this.product().taxId);
      return tax ? tax.rate || 0 : 0;
    },

    deliveryUnit(deliveryUnit) {
      if (!this.deliveryUnits || !deliveryUnit) {
        return null
      }

      return this.deliveryUnits.get(deliveryUnit) || null
    },

    deliveryTime() {
      if (this.product().deliveryTime && this.product().deliveryUnit) {
        const deliveryUnit = this.deliveryUnit(this.product().deliveryUnit)
        if (deliveryUnit) {
          return this.product().deliveryTime + " " + deliveryUnit.name
        }
      }

      const warehouse = this.warehouse()
      if (warehouse) {
        const deliveryUnit = this.deliveryUnit(warehouse.deliveryUnit)
        if (deliveryUnit) {
          return warehouse.deliveryTime + " " + deliveryUnit.name
        }
      }

      return this.$t('products.no-delivery-time')
    },

    shippingCostsForProduct() {
      if (!this.shippingCosts || this.shippingCosts.size === 0) {
        return null;
      }

      return this.shippingCosts.get(this.product().id) || null;
    },

    shippingCostsText() {
      const shippingCostsForProduct = this.shippingCostsForProduct()
      if (!shippingCostsForProduct) {
        return "-"
      }

      let value = ""
      for (const shippingCost of shippingCostsForProduct) {
        value += `${shippingCost.name}: ${priceHelper.format(shippingCost.cost, this.$store.getters['market/currentCurrency'])} `
      }

      if (value.endsWith(' ')) {
        value = value.substring(0, value.length - 1)
      }

      return value
    },

    ean() {
      if (!this.product().ean) {
        return []
      }

      return this.product().ean.filter(element => element)
    }
  }
}
</script>